<template>
    <div>
        <div class="top">
            <div class="title">购买记录</div>
        </div>
        <div class="news">
            <div v-for="(item, index) in list" :key="index">
                <div class="news-list" v-if="item.State == 1">
                    <div class="news-list-left">
                        <div class="news-list-title">Plus会员年卡</div>
                        <div>
                            <span>{{ item.CreatTime }} 支付成功</span>
                        </div>
                    </div>
                    <div class="info">
                        有效期 {{ item.StartTime }} - {{ item.EndTime }}
                    </div>
                    <div class="news-list-title" style="margin-top:20px">
                        实付 ￥ {{ item.Price }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getList } from './service'

export default {
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            getList().then(res => {
                this.list = res.data
            })
        }
    }
}
</script>

<style lang="less" scoped>
.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }
}

.news-list {
    border: 1px solid #f4f4f4;
    margin: 20px 0;
    border-radius: 5px;
    padding: 20px;

    .news-list-left {
        display: flex;
        justify-content: space-between;
    }

    .clone {
        margin-left: 20px;
        cursor: pointer;
        i {
            font-size: 8px;
        }
    }

    .news-list-title {
        font-family: 'ct';
    }

    .info {
        margin-top: 20px;
        color: #666666;
        font-size: 13px;
    }
}
</style>
